import { Fragment, useCallback } from 'react';
import { observer } from 'decorators';
import { useDisposable } from 'hooks';
import { Grid, Typography } from '@material-ui/core';
import { SettingsSection, SettingsItem } from 'components/settings';
import Button from 'components/button';
import SaveButton from 'components/button/save';
import Modal from 'components/modal';
import Input, { Form } from 'components/form/input';
import auth from 'services/auth';

import VideoBlocksStore, { getCurrentVideoBlocksPricing } from './videoblocks.store';

export default observer(function VideoBlocksView() {
  if (!auth.user.roles.isDirector || !auth.user.paymentsEnabled) { return null; }

  const pricing = getCurrentVideoBlocksPricing();

  return <SettingsSection
    title="Video Storage Blocks"
    description="Video storage blocks save telehealth video recordings (Coming Soon) as well as video files uploaded to Shared Uploads, Documents & Templates.
          The Virtual Practice Plus (Coming Soon) plan includes 10 hours of video storage. Additional storage can be purchased for all plans. Each block
          of storage equals 10 hours."
  >
    <SettingsItem
      title="Video Storage Blocks"
      secondary={
        <Typography>
          {pricing}
        </Typography>
      }
      action={<VideoBlocksManageButton color="primary">Modify</VideoBlocksManageButton>}
    />
  </SettingsSection>;
});

export const VideoBlocksManageButton = observer(function VideoBlocksManageButton({ disabled, ...props }) {
  const store = useDisposable(() => new VideoBlocksStore(), []);
  const handleEdit = useCallback(() => store.editVideoBlocks(), [ store ]);

  if (!auth.user.roles.isDirector || auth.isLockedOut || !auth.user.paymentsEnabled) { return null; }

  return <Fragment>
    <Button {...props} onClick={handleEdit} disabled={disabled || !auth.user.hasSubscription} />
    <VideoBlocksEdit store={store} />
  </Fragment>;
});

const VideoBlocksEdit = observer(function VideoBlocksEdit({ store }) {
  const { videoBlocksForm, videoBlocksDescription, videoBlocksHasChange } = store;

  const handleClose = useCallback(() => store.closeVideoBlocksEdit(), [ store ]);
  const saveFunc = useCallback(() => store.saveVideoBlocks(), [ store ]);

  if (!videoBlocksForm) { return null; }

  return <Modal open disableBackdropClick label="Modify Video Storage Blocks?" onClose={handleClose}>
    <Form>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Input form={videoBlocksForm} field="blocks" />
        </Grid>
        <Grid item>
          { videoBlocksDescription }
        </Grid>
        <Grid item>
          <strong>You will be immediately charged the pro-rata amount for the current billing period.</strong>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs />
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>&nbsp;
              <SaveButton color="primary" saveFunc={saveFunc} disabled={!videoBlocksHasChange}>Pay Now</SaveButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  </Modal>;
});